<template>
	<div>
		<video ref="video" height="100%" width="100%" id="video" autoplay></video>
	</div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";
export default {
  name: 'QrCodeScannerComponent',
  mounted() {
    this.openScan();
  },
  methods: {
    async openScan() {
      const codeReader = new BrowserMultiFormatReader();
      await codeReader.getVideoInputDevices().then(videoInputDevices => {
        const firstDeviceId = videoInputDevices[0].deviceId;
        this.decodeFromInputVideoFunc(firstDeviceId);
      });
    },
    decodeFromInputVideoFunc(firstDeviceId) {
      const codeReader = new BrowserMultiFormatReader();
      codeReader.decodeFromInputVideoDeviceContinuously(
        firstDeviceId,
        'video',
        (result, err) => {
          if (result) {
            console.log(result.text); // 处理扫描结果
          }
          if (err) {
            console.error(err);
          }
        }
      );
    }
  },
  beforeDestroy() {
    const video = this.$refs.video;
    if (video) {
      video.srcObject.getTracks()[0].stop();
    }
  }
}
</script>