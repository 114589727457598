import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login'
import Register from "../views/Register";
import Index from "../views/Index";
import Welcome from '../views/Welcome';
import User from '../views/User';
import Role from '../views/Role';
import Product from '../views/Product';
import ProductFunction from "../views/ProductFunction";
import Device from "../views/Device";
import Personal from "../views/Personal";
import NotFound from "../views/NotFound";
import Enterprise from "../views/Enterprise";
import DeviceLog from "../views/DeviceLog";
import OTA from "../views/OTA";
import Distinguish from "../views/Distinguish";
import scan_code from "../components/scan_code_page";
import scanCode from "../views/scanCode";
import N01_scanCode from "../views/N01_scanCode";
import QrCodeScannerComponent from "../components/QrCodeScannerComponent";

const originalReplace = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalReplace.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Login',
	component: Login
}, {
	path: '/about',
	name: 'About',
	// route level code-splitting
	// this generates a separate chunk (about.[hash].js) for this route
	// which is lazy-loaded when the route is visited.
	component: function() {
		return import( /* webpackChunkName: "about" */ '../views/About.vue')
	}
}, {
	path: '/register',
	component: Register
}, {
	path: '/scanCode',
	component: scanCode
}, {
	path: '/N01_scanCode',
	component: N01_scanCode
}, {
	path: '/scan_code',
	name: 'scan_code',
	component: scan_code
}, {	
	path: '/QrCodeScannerComponent',
	name: 'QrCodeScannerComponent',
	component: QrCodeScannerComponent
}, {
	path: '/distinguish',
	meta: {
		requireAuth: true
	},
	component: Distinguish
}, {
	path: '/index',
	meta: {
		requireAuth: true
	},
	component: Index,
	redirect: '/index/welcome',
	children: [{
		name: 'welcome',
		path: 'welcome',
		meta: {
			requireAuth: true
		},
		component: Welcome
	}, {
		name: 'user',
		path: 'user',
		meta: {
			requireAuth: true
		},
		component: User
	}, {
		name: 'role',
		path: 'role',
		meta: {
			requireAuth: true
		},
		component: Role
	}, {
		name: 'product',
		path: 'product',
		meta: {
			requireAuth: true
		},
		component: Product
	}, {
		name: 'productFunction',
		path: 'productFunction',
		meta: {
			requireAuth: true
		},
		component: ProductFunction
	}, {
		name: 'device',
		path: 'device',
		meta: {
			requireAuth: true
		},
		component: Device
	}, {
		name: 'personal',
		path: 'personal',
		meta: {
			requireAuth: true
		},
		component: Personal
	}, {
		name: 'enterprise',
		path: 'enterprise',
		meta: {
			requireAuth: true
		},
		component: Enterprise
	}, {
		name: 'deviceLog',
		path: 'deviceLog',
		meta: {
			requireAuth: true
		},
		component: DeviceLog
	}, {
		name: 'ota',
		path: 'ota',
		meta: {
			requireAuth: true
		},
		component: OTA
	}]
}, {
	path: '*',
	component: NotFound
}];

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	// base: "/admin/",
	routes
});

export default router