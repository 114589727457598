<template>
	<div class="page-scan">
		<!--返回-->
		<<!-- van-nav-bar title="扫描二维码/条形码" fixed @click-left="clickIndexLeft()" class="scan-index-bar">
			<template #left>
				<van-icon name="arrow-left" size="18" color="#fff" />
				<span style="color: #fff"> 取消 </span>
			</template>
		</van-nav-bar> -->
		<!-- 切换摄像头按钮 -->
		<button class="toggle-camera-btn" @click="toggleCamera">
			<i class="fas fa-camera-switch"></i> <!-- 使用 Font Awesome 图标 -->
		</button>
		<!-- 扫码区域 -->
		<video ref="video" id="video" class="scan-video" autoplay></video>
		<!-- 提示语 -->
		<div v-show="tipShow" class="scan-tip"> {{tipMsg}} </div>
	</div>
</template>

<script>
	import {
		BrowserMultiFormatReader
	} from '@zxing/library';

	export default {
		name: 'scanCodePage',
		data() {
			return {
				loadingShow: false,
				codeReader: null,
				scanText: '',
				vin: null,
				tipMsg: '正在尝试识别....',
				tipShow: false,
				firstDeviceId: null,
				switchoverDeviceId: null,
				passed: false
			}
		},
		created() {
			this.codeReader = new BrowserMultiFormatReader();
			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			    // 支持摄像头访问
			} else {
			    this.tipMsg = '您的设备不支持摄像头访问。';
			}
			this.openScan();
		},
		destroyed() {
			this.codeReader.reset();
		},
		watch: {
			'$route'(to, from) {
				if (to.path == '/scanCodePage') {
					this.codeReader = new BrowserMultiFormatReader();
					this.openScanTwo();
				}
			}
		},
		methods: {
			async openScan() {
				this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
					this.tipShow = true;
					this.tipMsg = '正在调用摄像头...';
					console.log('videoInputDevices', videoInputDevices);
					// 默认获取第一个摄像头设备id
					let firstDeviceId = videoInputDevices[0].deviceId;
					// 获取第一个摄像头设备的名称
					const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
					if (videoInputDevices.length > 1) {
						// 判断是否后置摄像头
						if (videoInputDeviceslablestr.indexOf('back') > -1) {
							firstDeviceId = videoInputDevices[0].deviceId;
							this.firstDeviceId = videoInputDevices[0].deviceId;
						} else {
							firstDeviceId = videoInputDevices[1].deviceId;
							this.switchoverDeviceId = videoInputDevices[1].deviceId;
						}
					}
					this.nowDeviceId = firstDeviceId;
					this.decodeFromInputVideoFunc(firstDeviceId);
				}).catch(err => {
					this.tipShow = false;
					console.error(err);
				});
			},
			async openScanTwo() {
				this.codeReader = await new BrowserMultiFormatReader();
				this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
					this.tipShow = true;
					this.tipMsg = '正在调用摄像头...';
					console.log('videoInputDevices', videoInputDevices);
					// 默认获取第一个摄像头设备id
					let firstDeviceId = videoInputDevices[0].deviceId;
					// 获取第一个摄像头设备的名称
					const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
					if (videoInputDevices.length > 1) {
						// 判断是否后置摄像头
						if (videoInputDeviceslablestr.indexOf('back') > -1) {
							firstDeviceId = videoInputDevices[0].deviceId;
						} else {
							firstDeviceId = videoInputDevices[1].deviceId;
						}
					}
					this.decodeFromInputVideoFunc(firstDeviceId);
				}).catch(err => {
					this.tipShow = false;
					console.error(err);
				});
			},
			toggleCamera(){
				// if (this.$route.params.option === "N01"){
				// 	const data = { ...this.$route.params.data };
				// 	let [mac, glucose] = "112233445566;7.0".split(";");
				// 	data.mac = mac
				// 	data.glucose = glucose
				// 	console.log(data)
				// 	// 直接调用接口
				// 	var _this = this;
				// 	$.ajax({
				// 		type: "post",
				// 		url: "/wave-device/noriton_device/binding",
				// 		contentType: "application/json;charset=UTF-8",
				// 		dataType: "json",
				// 		data: JSON.stringify(data),
				// 		success(result) {
				// 			if (result.code === 20000) {
				// 				_this.$message.success(result.message);
				// 			} else {
				// 				_this.$message.error(result.message);
				// 			}
				// 		}
				// 	});
				// }
				let firstDeviceId = this.firstDeviceId == this.nowDeviceId ? this.switchoverDeviceId : this.firstDeviceId;
				this.nowDeviceId = firstDeviceId;
				// console.log(firstDeviceId)
				// console.log(this.nowDeviceId)
				this.decodeFromInputVideoFunc(firstDeviceId);
			},
			decodeFromInputVideoFunc(firstDeviceId) {
				this.codeReader.reset(); // 重置
				this.scanText = '';
				this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
					this.tipMsg = '正在尝试识别...';
					this.scanText = '';
					if (result) {
						console.log('扫描结果', result);
						this.scanText = result.text;
						if (this.scanText && !this.passed) { // passed控制不要重复扫描添加数据
							this.tipShow = false;
							// 这部分接下去的代码根据需要，读者自行编写了
							// this.$store.commit('app/SET_SCANTEXT', result.text);
							// console.log('已扫描的小票列表', this.$store.getters.scanTextArr);
							if (this.$route.params.option === "N01"){
								const data = { ...this.$route.params.data };
								let [mac, glucose] = result.text.split(";");
								data.mac = mac
								data.glucose = glucose
								// 直接调用接口
								var _this = this;
								$.ajax({
									type: "post",
									url: "/wave-device/noriton_device/binding",
									contentType: "application/json;charset=UTF-8",
									dataType: "json",
									data: JSON.stringify(data),
									success(result) {
										if (result.code === 20000) {
											_this.$message.success(result.message);
										} else {
											_this.$message.error(result.message);
										}
										_this.passed = true
									}
								});
							} else {
								sessionStorage.setItem(this.$route.params.option, result.text);
							}
							//返回原页面
							setTimeout(()=>{
								this.$router.back();
							},1000)
						}
					}
					if (err && !(err)) {
						this.tipMsg = '识别失败';
						setTimeout(() => {
							this.tipShow = false;
						}, 2000)
						console.error(err);
					}
				});
			},
			clickIndexLeft() { // 返回上一页
				this.codeReader = null;
				this.$destroy();
				this.$router.back();
			}
		}
	}
</script>

<style>
	
	body {
	    margin: 0 auto;
	}
	.scan-index-bar {
		background-image: linear-gradient(-45deg, #42a5ff, #59cfff);
	}

	.van-nav-bar__title {
		color: #fff !important;
	}

	.scan-video {
		height: 100vh;
		width: 100%;
	}

	.scan-tip {
		width: 100vw;
		text-align: center;
		margin-bottom: 10vh;
		color: white;
		font-size: 5vw;
	}

	.page-scan {
		overflow-y: hidden;
		background-color: #363636;
	    position: relative; /* 确保子元素的绝对定位基于此容器 */
	}
	
	.toggle-camera-btn {
	    position: absolute;
	    top: 10px; /* 根据需要调整距离顶部的距离 */
	    right: 10px; /* 根据需要调整距离右侧的距离 */
	    background: none;
	    border: none;
	    font-size: 24px; /* 根据需要调整图标大小 */
	    cursor: pointer;
	    z-index: 10; /* 确保按钮在最上层 */
		background-color: #ddd;
		width: 20px;
		height: 20px;
	}
	
</style>
